/* global  */ // tUrl等のグローバル変数を宣言する
// import Swiper from 'swiper' // Swiper
// import PerfectScrollbar from 'perfect-scrollbar' // 独自スクロールバー
// position:styckyのポリフィル
// Stickyfill.add(document.querySelectorAll('.sticky'))

jQuery(function ($) {
  // ######## ########  ##     ## ##    ## ##    ##  #######
  //    ##    ##     ## ##     ## ###   ## ##   ##  ##     ##
  //    ##    ##     ## ##     ## ####  ## ##  ##   ##     ##
  //    ##    ########  ##     ## ## ## ## #####     #######
  //    ##    ##   ##   ##     ## ##  #### ##  ##   ##     ##
  //    ##    ##    ##  ##     ## ##   ### ##   ##  ##     ##
  //    ##    ##     ##  #######  ##    ## ##    ##  #######
  function trunk8() {
    $('.js-t8.line1').trunk8({
      // 指定要素に行数制限をかける
      lines: 1,
      fill: '...'
    })
    $('.js-t8.line2').trunk8({
      // 指定要素に行数制限をかける
      lines: 2,
      fill: '...'
    })
  }
  trunk8()
  //trunk8処理関数を実行
})
